.container {

ul {
    list-style: none;
    display: flex;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
}

li:after {
    font-family: "icomoon";
    content: " \eb1b  ";
}

li:last-child:after {
    content: unset;
}

@media (max-width: 460px) {
    li {
        display: none;
    }

    li:last-child {
        display: flex;
    }

    li:nth-last-child(2) {
        display: flex;
    }

    ul {
        flex-direction: column;
        align-items: flex-start;
    }
}

}
