.default {
    color: white;
    background-color: grey;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: var(--border-radius);
}

.INVOICE {
    background-color: #0ead69;
}

.REFUND {
    background-color: #a800a8;
}

.CREDIT_NOTE {
    background-color: darkslategray;
}

.QUOTATION {
    background-color: #024b9b;
}

.ACCEPTED_QUOTATION {
    background-color: #30571e;
}

.DECLINED_QUOTATION {
    background-color: #9e1a1a;
}

.PREORDER_INVOICE {
    background-color: #ff6300;
}