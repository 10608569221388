.container {
    min-width: 250px;
    max-width: 320px;
    flex: 1;
    height: 500px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    position: relative;
}

.container:hover {
    visibility: visible;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-in;
    z-index: var(--zIndex-product-card);
    -webkit-box-shadow: 0 8px 13px 2px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: 0 8px 13px 2px rgba(0, 0, 0, 0.26);
    box-shadow: 0 8px 13px 2px rgba(0, 0, 0, 0.26);
}

.container:hover .pictureFront {
    opacity: 0;
}

.container:hover .pictureBack {
    opacity: 1;
}

.labels {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    right: 0;
}

.pictureFront {
    opacity: 1;
}

.pictureBack {
    opacity: 0;
}

.singlePicture {
    opacity: 1 !important;
}

.picture,
.pictureBack,
.pictureFront {
    position: absolute;
    -webkit-transition: opacity 0.2s ease-in-out;
    -moz-transition: opacity 0.2s ease-in-out;
    -o-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
}

.picture {
    position: relative;
    height: 220px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: start;
}

.pictureBack,
.pictureFront {
    height: inherit;
    background-size: cover;
    object-fit: contain;
    background-color: white;
}

.title {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}

.price {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
}

.regularPrice {
    text-decoration: line-through;
    font-size: 14px;
    font-weight: 600;
    color: var(--color-text-gray);
}
