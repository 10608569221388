.container {
    background: var(--color-primary-50);
    border: 1px solid var(--color-primary-100);
    width: fit-content;
    height: 100%;
    padding: 24px 16px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: relative;
}

.container span {
    color: var(--color-primary-100);
    font-size: 40px;
    align-self: center;
    position: absolute;
    top: 8px;
    right: 8px;
}

.container h3 {
    font-size: 16px;
    color: var(--color-primary-500);
    font-weight: 600;
    padding-right: 64px;
}

.container p {
    font-size: 14px !important;
    color: var(--color-primary-700) !important;
}
