.date {
    font-size: 14px;
    color: grey;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.nameContainer {
    display: flex;
    align-items: center;
    gap: 8px;
}

.nameIcon {
    width: 32px;
    height: 32px;
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: white;
    background: var(--color-text-gray);
    opacity: 0.6;
}

.name {
    color: var(--color-text-gray) !important;
    font-weight: normal !important;
    font-size: 16px;
}

.titleContainer {
    display: flex;
    gap: 4px;
    align-items: baseline;
}

.title {
    color: var(--color-text-gray) !important;
}

.comment {
    line-height: 1.75;
    color: var(--color-text-gray);
}

@media (max-width: 768px) {
    .comment {
        font-size: 14px;
    }

    .titleContainer {
        flex-direction: column-reverse;
        gap: 8px;
    }
}
