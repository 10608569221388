.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-bottom: 3%;
}
.column_center_left {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 1%;
}
.column_center_right {
    padding: 1%;
}
.title {
    margin-bottom: 20px;
    font-size: medium;
    font-weight: bold;
}
.container_slider {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.slider {
    width: 50% !important;
}
.icon_more {
    font-weight: bold;
    font-size: x-large;
    padding: 1%;
    margin: 1%;
    cursor: pointer;
}
.icon_moin {
    font-weight: bold;
    font-size: x-large;
    padding: 1%;
    margin: 1%;
    cursor: pointer;
}
.value_slider {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.imgGiftCard {
    margin-right: 10px;
    margin-top: 20px;
}
.inputValue {
    width: 15%;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: x-large;
    text-align: center;
    vertical-align: center;
    margin-right: 10px;
}

.btnInputValue {
    background-color: var(--color-footer-bg) !important;
    color: white !important;
    margin-left: 10px;
}

.containerInputValue {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.text {
    font-size: small;
    margin-bottom: 20px;
}

@media (max-width: 1000px) {
    .container {
        flex-direction: column;
        padding: 2%;
    }
    .column_center_left {
        margin-top: 2%;
        margin-bottom: 2%;
    }
    .column_center_right {
        margin-top: 2%;
        margin-bottom: 2%;
    }
}

@media (max-width: 1500px) {
    .column_center_right {
        flex-grow: 3;
    }
    .column_center_left{
        flex-grow: 3;
    }
}
