:root {
    --xs: 5px;
    --sm: 10px;
    --md: 20px;
    --lg: 50px;
    --xl: 100px;

    --bp-xl: 1200px;
    --bp-lg: 992px;
    --bp-md: 768px;
    --bp-sm: 576px;
    --bp-xs: 400px;

    --app-width: 1400px;

    --border-radius: 5px;
    --input-height: 36px;
}
