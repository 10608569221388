.banner {
    height: auto;
    width: 100%;
    object-fit: contain;
}

.mobileBanner {
    display: none;
}

.desktopBanner {
    display: block;
}

@media (max-width: 600px) {
    .desktopBanner {
        display: none;
    }
    .mobileBanner {
        display: block;
    }
}
