.container {
    position: relative;
    height: 100%;
}

.menu {
    color: var(--color-top-header-font);
    cursor: pointer;
    position: relative;
    height: 100%;
    font-size: 15px;
}

.menuHighlighted {
    background-color: red !important;
}

.text,
.textHighlighted {
    font-weight: bold;
    max-width: 200px;
}

.text {
    color: white;
    padding: 5px 0;
    border-bottom: solid 2px transparent;
    transition: all 0.3s;
    background-color: var(--color-header-bg);
}

.textHighlighted {
    font-weight: 900;
    padding: 2px;
}

.image {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 5px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-shrink: 0;
}

.menu_active_user {
    background-color: var(--color-top-header-bg) !important;
}

.menu_active_user .text {
    color: var(--color-secondary) !important;
}

.menuSubtitle {
    position: absolute;
    bottom: 13px;
    font-size: 15px;
    font-weight: normal;
    color: #68cc9a !important;
    text-decoration: none !important;
}

.menuSubIcon {
    color: #fff;
    margin-right: 10px;
    font-size: 18px;
    display: block;
}

@media (hover) {
    .menu:hover .text {
        color: var(--color-secondary);
    }
}

@media (max-width: 1300px) {
    .menu {
        font-size: 14px;
    }
}
