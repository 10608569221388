.innerContent {
    background-color: white;
    border-radius: 5px;
    outline: none;
}

.frame {
    border-radius: 30px;
}

.emailFrame {
    border: 1px solid black;
    border-radius: 30px;
    width: 300px;
}

.emailFrame:focus {
    border: 2px solid var(--color-primary);
}

.button {
    right: 0px;
    display: block;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #1972f5;
}

.disabledButton {
    background-color: gray;
}

.emailLogo {
    color: white;
    font-size: 20px;
}

.img {
    width: 300px;
}

@media (max-width: 503px) {
    .container {
        bottom: 14px;
        left: 78px;
        height: 54px;
        width: 54px;
    }
}
