.container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 30%;
    right: 0;
    z-index: var(--zIndex-mobileMenu);
    background-color: var(--color-primary);
    color: var(--color-text-on-primary);
    padding: 20px;
    overflow: auto;
    text-align: center;
    transform: translateX(100vw);
    transition: all 0.4s ease-in-out;
}

.fullWidthContainer {
    left: 0;
}

.initiation {
    cursor: pointer;
    color: white !important;
    font-weight: bold;
}

.opened {
    display: block;
    transform: translateX(0);
}

.logo {
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 40px;
}

.closeIcon {
    position: absolute !important;
    top: 10px;
    right: 20px;
}

.link_giftCard {
    color: #fff !important;
    font-weight: bold;
    margin-top: 4%;
    margin-bottom: 4%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.heart {
    font-size: 25px;
    color: red !important;
    animation: beat 2.5s infinite alternate;
    transform-origin: center;
    margin-right: 4px;
}

@keyframes beat {
    0% {
        transform: scale(0.8);
    }
    5% {
        transform: scale(0.9);
    }
    10% {
        transform: scale(0.8);
    }
    15% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.8);
    }
    100% {
        transform: scale(0.8);
    }
}
