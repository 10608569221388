.container {
    position: fixed;
    bottom: 20px;
    left: 24px;
    height: 60px;
    width: 60px;
    border-radius: 60px;
    z-index: var(--zIndex-overlay);
    background-color: #1972f5;
    font-size: 30px;
}

.logo {
    height: 100%;
    width: 100%;
    color: white !important;
}
.icon {
    color: white !important;
}

@media (max-width: 503px) {
    .container {
        bottom: 14px;
        left: 14px;
        height: 54px;
        width: 54px;
    }
}
