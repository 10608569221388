.link {
    cursor: pointer;
    display: flex;
    gap: 8px;
    align-items: baseline;
}

.text {
    color: var(--color-primary);
}

.text:hover {
    color: var(--color-primary-lighter);
}

.logo {
    width: 116px;
    height: 16px;
}
