.container {
    width: 100% !important;
    margin: 0 !important;
    border-radius: var(--border-radius);
    background-color: white;
}

.productList {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: space-between;
}
.product {
    flex: 1 0 20%;
}

@media (max-width: 1100px) {
    .product {
        flex: 1 0 30%;
        display: flex;
        justify-content: center;
    }

    .container h3 {
        text-align: center;
    }
}

@media (max-width: 900px) {
    .product {
        flex: 1 0 45%;
    }
}

@media (max-width: 690px) {
    .product {
        flex: 1 0 90%;
    }
}
