.container {
    position: absolute;
    left: 0;
    right: 0;
    color: #201a33;
    animation: subGroupOpening 0.3s ease-in;
    z-index: var(--zIndex-overlay);
}

.body {
    gap: 20px;
}

.bodyWrapper {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.subGroup {
    display: flex;
    left: 0;
    padding: 20px 0;
    min-width: 300px;
    font-size: 14px;
}

.subGroupBody {
    flex: 1;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.title {
    font-size: 24px !important;
    font-weight: 600;
    margin-bottom: 10px;
    white-space: nowrap;
}

.link:hover {
    opacity: 0.7;
}

@keyframes subGroupOpening {
    from {
        opacity: 0.1;
        transform: perspective(9cm) rotateX(-18deg);
    }
    60% {
        transform: perspective(9cm) rotateX(0deg);
    }
    to {
        opacity: 1;
    }
}

.subGroup h4 {
    font-size: 22px;
}

.relatedProducts {
    gap: 12px;
}

@media (max-width: 1400px) {
    .subGroup h4 {
        font-size: 18px;
    }
}

@media (max-width: 1200px) {
    .product-5 {
        display: none;
    }
}

@media (max-width: 1040px) {
    .product-4 {
        display: none;
    }
}
