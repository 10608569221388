.container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 1280px;
    margin: 0 auto;
    margin-bottom: 72px;
}

.innerContainer {
    text-align: center;
    gap: 64px;
}

.headband {
    color: white;
    background-color: var(--color-primary);
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.videoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    gap: 16px;
}
