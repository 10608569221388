.container {
    display: flex;
    gap: 48px;
    padding: 40px 0;
}

.container img {
    border: 1px solid var(--color-border);
    max-height: 700px;
}

.imageContainer {
    flex: 1;
    text-align: center;
}

.innerContainer {
    text-align: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

@media screen and (max-width: 915px) {
    .container {
        flex-direction: column-reverse;
    }
}

@media screen and (min-width: 915px) and (max-width: 1320px) {
    .container {
        padding: 40px 15px;
    }
}
