@import "../../../../../_css/variables/variables.css";

.container.root {
    border-radius: 20px 20px 20px 20px;
    min-height: var(--input-height);
    box-shadow: none !important;
    padding: 2px 20px;
}

.container.containedPrimary {
    color: var(--color-text-on-primary) !important;
}

.container.label {
    text-transform: none;
    font-weight: 600;
}

.container.containerForDelete {
    color: var(--color-delete);
}

.container.containerForDelete.contained {
    color: #FFF;
    background-color: var(--color-delete);
}

.container.sizeSmall {
    min-height: auto;
}

.container.sizeLarge {
    height: 40px;
}

.container.outlinedPrimary {
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
}
