:root {
    --zIndex-product-card: 70;
    --zIndex-navBar: 80;
    --zIndex-searchBar: 85;
    --zIndex-top-navBar: 90;
    --zIndex-mobileMenu: 100;
    --zIndex-mobileMenu-cross: 101;
    --zIndex-filter: 110;
    --zIndex-header: 900;
    --zIndex-overlay: 999;
}
