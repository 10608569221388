.container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    text-align: center;
    padding-left: 16px;
    padding-right: 16px;
}

.darkColors {
    background: var(--color-primary-darker);
    color: var(--color-text-on-primary);
}

.lightColors {
    background: var(--color-secondary-bg);
    color: var(--color-text);
}

.darkColors::placeholder {
    color: var(--color-text-on-primary);
    opacity: 0.5;
}

.lightColors::placeholder {
    color: var(--color-text);
    opacity: 0.5;
}

.inputContainer {
    width: 360px;
    align-self: center;
}

.title {
    font-size: 1.8rem;
    font-weight: bold;
}

.text {
    opacity: 0.8;
}

.icon {
    padding-right: 4px;
    padding-left: 16px;
}

@media screen and (max-width: 760px) {
    .title {
        font-size: 1.4rem;
    }

    .text {
        font-size: 0.9rem;
    }

    .inputContainer {
        width: 80%;
    }
}
