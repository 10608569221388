.container {
    position: relative;
    margin-left: 20px;
    cursor: pointer;
    z-index: var(--zIndex-top-navBar);
}

.flag {
    border-left: 1px solid #222;
    border-right: 1px solid #222;
    padding: 0 10px;
    height: 40px;
}

.flagImg {
    height: 24px;
    min-width: 24px;
}

.languages {
    position: absolute;
    background-color: white;
    color: var(--color-top-header-bg);
    padding-top: 5px;
    padding-bottom: 5px;
    min-width: 160px;
    font-size: 14px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
}

.flagHover {
    color: var(--color-secondary);
}

.lang {
    color: white;
}

.langHover {
    color: var(--color-secondary);
}

.language {
    padding: 3px 20px 3px 20px;
}

.language:hover {
    background-color: lightgrey;
}



@media (max-width: 1400px) {
    .languages {
        font-size: 12px;
        min-width: 120px;
        right: -24px;
    }
}

@media (max-width: 1000px) {
    .container {
        margin-right: 40px;
    }
}
