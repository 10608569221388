.container {
    border-bottom: 1px solid lightgrey;
    padding-bottom: 12px;
    gap: 24px;
}

.container:hover .name {
    text-decoration: underline;
}

.image,
.imagePlaceholder {
    width: 80px;
    height: 80px;
}

.price {
    font-weight: 600;
    white-space: nowrap;
}

@media (max-width: 640px) {
    .name,
    .price {
        font-size: 14px;
    }

    .image {
        width: 48px;
        height: 48px;
    }
}
