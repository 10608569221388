.modalContainer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: var(--zIndex-header);
    background-color: var(--color-secondLvl-bg);
    color: var(--color-text);
    padding: 8px;
    overflow: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.logo {
    margin-top: 16px;
}

.cross {
    position: absolute;
    right: 16px;
    top: 16px;
}

.modalTitle {
    font-weight: bold;
    width: 75%;
    text-align: start;
    margin-top: 16px;
}

.searchButton {

}

.searchButton:hover {
    cursor: pointer;
}
