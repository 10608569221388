@media (max-width: 500px) {
    .container {
        flex-direction: column;
        >* {
            margin-bottom: 5px;
        }
    }

    .arrow {
        display: none;
    }
}
