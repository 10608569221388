.container {
    gap: 12px;
    z-index: 1;
    padding: 16px 24px !important;
}

.container h2 {
    font-size: 20px;
}

.productImg {
    width: 100%;
    height: auto;
    display: block;
}

.content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    flex: 1;
}

.imagesContainer {
    width: 300px;
    height: 300px;
    position: relative;
}

@media (max-width: 780px) {
    .imagesContainer {
        width: 250px;
        height: 250px;
        position: relative;
    }
}

@media (max-width: 500px) {
    .imagesContainer {
        width: 200px;
        height: 200px;
        position: relative;
    }
}

@media (min-width: 500px) {
    .container h2 {
        font-size: 24px;
    }

    .container {
        gap: 24px;
        min-width: 398px;
        max-width: 700px;
        flex: 1;
        align-self: stretch;
        padding: 24px 32px !important;
    }

    .slide {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
