.container {
    margin-top: 40px;
}

.input {
    font-size: 10px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: -10px;
    margin-bottom: 10px;
}