@font-face {
    font-family: "icomoon";
    src: url(/static/assets/icomoon.7f32e144.ttf);
    src: url(/static/assets/icomoon.7f32e144.ttf) format("truetype"),
    url(/static/assets/icomoon.0dc7f000.woff) format("woff"),
    url(/static/assets/icomoon.27eabe90.svg#icomoon) format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-shopping-bag1:before {
    content: "\ec06";
}

.icon-store-front:before {
    content: "\ec05";
}

.icon-activity:before {
    content: "\e900";
}

.icon-home:before {
    content: "\e901";
}

.icon-home2:before {
    content: "\e902";
}

.icon-home3:before {
    content: "\e903";
}

.icon-office:before {
    content: "\e904";
}

.icon-newspaper:before {
    content: "\e905";
}

.icon-pencil1:before {
    content: "\e906";
}

.icon-pencil2:before {
    content: "\e907";
}

.icon-quill:before {
    content: "\e908";
}

.icon-pen:before {
    content: "\e909";
}

.icon-blog:before {
    content: "\e90a";
}

.icon-eyedropper:before {
    content: "\e90b";
}

.icon-droplet:before {
    content: "\e90c";
}

.icon-paint-format:before {
    content: "\e90d";
}

.icon-image:before {
    content: "\e90e";
}

.icon-images:before {
    content: "\e90f";
}

.icon-camera:before {
    content: "\e910";
}

.icon-headphones:before {
    content: "\e911";
}

.icon-music:before {
    content: "\e912";
}

.icon-play:before {
    content: "\e913";
}

.icon-film:before {
    content: "\e914";
}

.icon-video-camera:before {
    content: "\e915";
}

.icon-dice:before {
    content: "\e916";
}

.icon-pacman:before {
    content: "\e917";
}

.icon-spades:before {
    content: "\e918";
}

.icon-clubs:before {
    content: "\e919";
}

.icon-diamonds:before {
    content: "\e91a";
}

.icon-bullhorn:before {
    content: "\e91b";
}

.icon-connection:before {
    content: "\e91c";
}

.icon-podcast:before {
    content: "\e91d";
}

.icon-feed:before {
    content: "\e91e";
}

.icon-mic:before {
    content: "\e91f";
}

.icon-book:before {
    content: "\e920";
}

.icon-books:before {
    content: "\e921";
}

.icon-library:before {
    content: "\e922";
}

.icon-file-text:before {
    content: "\e923";
}

.icon-profile:before {
    content: "\e924";
}

.icon-file-empty:before {
    content: "\e925";
}

.icon-files-empty:before {
    content: "\e926";
}

.icon-file-text2:before {
    content: "\e927";
}

.icon-file-picture:before {
    content: "\e928";
}

.icon-file-music:before {
    content: "\e929";
}

.icon-file-play:before {
    content: "\e92a";
}

.icon-file-video:before {
    content: "\e92b";
}

.icon-file-zip:before {
    content: "\e92c";
}

.icon-copy:before {
    content: "\e92d";
}

.icon-paste:before {
    content: "\e92e";
}

.icon-stack:before {
    content: "\e92f";
}

.icon-folder:before {
    content: "\e930";
}

.icon-folder-open:before {
    content: "\e931";
}

.icon-folder-plus:before {
    content: "\e932";
}

.icon-folder-minus:before {
    content: "\e933";
}

.icon-folder-download:before {
    content: "\e934";
}

.icon-folder-upload:before {
    content: "\e935";
}

.icon-price-tag:before {
    content: "\e936";
}

.icon-price-tags:before {
    content: "\e937";
}

.icon-barcode:before {
    content: "\e938";
}

.icon-qrcode:before {
    content: "\e939";
}

.icon-ticket:before {
    content: "\e93a";
}

.icon-cart:before {
    content: "\e93b";
}

.icon-coin-dollar:before {
    content: "\e93c";
}

.icon-coin-euro:before {
    content: "\e93d";
}

.icon-coin-pound:before {
    content: "\e93e";
}

.icon-coin-yen:before {
    content: "\e93f";
}

.icon-credit-card:before {
    content: "\e940";
}

.icon-calculator:before {
    content: "\e941";
}

.icon-lifebuoy:before {
    content: "\e942";
}

.icon-phone:before {
    content: "\e943";
}

.icon-phone-hang-up:before {
    content: "\e944";
}

.icon-address-book:before {
    content: "\e945";
}

.icon-envelop:before {
    content: "\e946";
}

.icon-pushpin:before {
    content: "\e947";
}

.icon-location:before {
    content: "\e948";
}

.icon-location2:before {
    content: "\e949";
}

.icon-compass:before {
    content: "\e94a";
}

.icon-compass2:before {
    content: "\e94b";
}

.icon-map:before {
    content: "\e94c";
}

.icon-map2:before {
    content: "\e94d";
}

.icon-history:before {
    content: "\e94e";
}

.icon-clock:before {
    content: "\e94f";
}

.icon-clock2:before {
    content: "\e950";
}

.icon-alarm:before {
    content: "\e951";
}

.icon-bell:before {
    content: "\e952";
}

.icon-stopwatch:before {
    content: "\e953";
}

.icon-calendar:before {
    content: "\e954";
}

.icon-printer:before {
    content: "\e955";
}

.icon-keyboard:before {
    content: "\e956";
}

.icon-display:before {
    content: "\e957";
}

.icon-laptop:before {
    content: "\e958";
}

.icon-mobile:before {
    content: "\e959";
}

.icon-mobile2:before {
    content: "\e95a";
}

.icon-tablet:before {
    content: "\e95b";
}

.icon-tv:before {
    content: "\e95c";
}

.icon-drawer:before {
    content: "\e95d";
}

.icon-drawer2:before {
    content: "\e95e";
}

.icon-box-add:before {
    content: "\e95f";
}

.icon-box-remove:before {
    content: "\e960";
}

.icon-download:before {
    content: "\e961";
}

.icon-upload:before {
    content: "\e962";
}

.icon-floppy-disk:before {
    content: "\e963";
}

.icon-drive:before {
    content: "\e964";
}

.icon-database:before {
    content: "\e965";
}

.icon-undo:before {
    content: "\e966";
}

.icon-redo:before {
    content: "\e967";
}

.icon-undo2:before {
    content: "\e968";
}

.icon-redo2:before {
    content: "\e969";
}

.icon-forward:before {
    content: "\e96a";
}

.icon-reply:before {
    content: "\e96b";
}

.icon-bubble:before {
    content: "\e96c";
}

.icon-bubbles:before {
    content: "\e96d";
}

.icon-bubbles2:before {
    content: "\e96e";
}

.icon-bubble2:before {
    content: "\e96f";
}

.icon-bubbles3:before {
    content: "\e970";
}

.icon-bubbles4:before {
    content: "\e971";
}

.icon-user:before {
    content: "\e972";
}

.icon-users:before {
    content: "\e973";
}

.icon-user-plus:before {
    content: "\e974";
}

.icon-user-minus:before {
    content: "\e975";
}

.icon-user-check:before {
    content: "\e976";
}

.icon-user-tie:before {
    content: "\e977";
}

.icon-quotes-left:before {
    content: "\e978";
}

.icon-quotes-right:before {
    content: "\e979";
}

.icon-hour-glass:before {
    content: "\e97a";
}

.icon-spinner:before {
    content: "\e97b";
}

.icon-spinner2:before {
    content: "\e97c";
}

.icon-spinner3:before {
    content: "\e97d";
}

.icon-spinner4:before {
    content: "\e97e";
}

.icon-spinner5:before {
    content: "\e97f";
}

.icon-spinner6:before {
    content: "\e980";
}

.icon-spinner7:before {
    content: "\e981";
}

.icon-spinner8:before {
    content: "\e982";
}

.icon-spinner9:before {
    content: "\e983";
}

.icon-spinner10:before {
    content: "\e984";
}

.icon-spinner11:before {
    content: "\e985";
}

.icon-binoculars:before {
    content: "\e986";
}

.icon-search:before {
    content: "\e987";
}

.icon-zoom-in:before {
    content: "\e988";
}

.icon-zoom-out:before {
    content: "\e989";
}

.icon-enlarge:before {
    content: "\e98a";
}

.icon-shrink:before {
    content: "\e98b";
}

.icon-enlarge2:before {
    content: "\e98c";
}

.icon-shrink2:before {
    content: "\e98d";
}

.icon-key:before {
    content: "\e98e";
}

.icon-key2:before {
    content: "\e98f";
}

.icon-lock:before {
    content: "\e990";
}

.icon-unlocked:before {
    content: "\e991";
}

.icon-wrench:before {
    content: "\e992";
}

.icon-equalizer:before {
    content: "\e993";
}

.icon-equalizer2:before {
    content: "\e994";
}

.icon-cog:before {
    content: "\e995";
}

.icon-cogs:before {
    content: "\e996";
}

.icon-hammer:before {
    content: "\e997";
}

.icon-magic-wand:before {
    content: "\e998";
}

.icon-aid-kit:before {
    content: "\e999";
}

.icon-bug:before {
    content: "\e99a";
}

.icon-pie-chart:before {
    content: "\e99b";
}

.icon-stats-dots:before {
    content: "\e99c";
}

.icon-stats-bars:before {
    content: "\e99d";
}

.icon-stats-bars2:before {
    content: "\e99e";
}

.icon-trophy:before {
    content: "\e99f";
}

.icon-gift:before {
    content: "\e9a0";
}

.icon-glass:before {
    content: "\e9a1";
}

.icon-glass2:before {
    content: "\e9a2";
}

.icon-mug:before {
    content: "\e9a3";
}

.icon-spoon-knife:before {
    content: "\e9a4";
}

.icon-leaf:before {
    content: "\e9a5";
}

.icon-rocket:before {
    content: "\e9a6";
}

.icon-meter:before {
    content: "\e9a7";
}

.icon-meter2:before {
    content: "\e9a8";
}

.icon-hammer2:before {
    content: "\e9a9";
}

.icon-fire:before {
    content: "\e9aa";
}

.icon-lab:before {
    content: "\e9ab";
}

.icon-magnet:before {
    content: "\e9ac";
}

.icon-bin:before {
    content: "\e9ad";
}

.icon-bin2:before {
    content: "\e9ae";
}

.icon-briefcase:before {
    content: "\e9af";
}

.icon-airplane:before {
    content: "\e9b0";
}

.icon-truck:before {
    content: "\e9b1";
}

.icon-road:before {
    content: "\e9b2";
}

.icon-accessibility:before {
    content: "\e9b3";
}

.icon-target:before {
    content: "\e9b4";
}

.icon-shield:before {
    content: "\e9b5";
}

.icon-power:before {
    content: "\e9b6";
}

.icon-switch:before {
    content: "\e9b7";
}

.icon-power-cord:before {
    content: "\e9b8";
}

.icon-clipboard:before {
    content: "\e9b9";
}

.icon-list-numbered:before {
    content: "\e9ba";
}

.icon-list:before {
    content: "\e9bb";
}

.icon-list2:before {
    content: "\e9bc";
}

.icon-tree:before {
    content: "\e9bd";
}

.icon-menu:before {
    content: "\e9be";
}

.icon-menu2:before {
    content: "\e9bf";
}

.icon-menu3:before {
    content: "\e9c0";
}

.icon-menu4:before {
    content: "\e9c1";
}

.icon-cloud:before {
    content: "\e9c2";
}

.icon-cloud-download:before {
    content: "\e9c3";
}

.icon-cloud-upload:before {
    content: "\e9c4";
}

.icon-cloud-check:before {
    content: "\e9c5";
}

.icon-download2:before {
    content: "\e9c6";
}

.icon-upload2:before {
    content: "\e9c7";
}

.icon-download3:before {
    content: "\e9c8";
}

.icon-upload3:before {
    content: "\e9c9";
}

.icon-sphere:before {
    content: "\e9ca";
}

.icon-earth:before {
    content: "\e9cb";
}

.icon-link:before {
    content: "\e9cc";
}

.icon-flag:before {
    content: "\e9cd";
}

.icon-attachment:before {
    content: "\e9ce";
}

.icon-eye:before {
    content: "\e9cf";
}

.icon-eye-plus:before {
    content: "\e9d0";
}

.icon-eye-minus:before {
    content: "\e9d1";
}

.icon-eye-blocked:before {
    content: "\e9d2";
}

.icon-bookmark:before {
    content: "\e9d3";
}

.icon-bookmarks:before {
    content: "\e9d4";
}

.icon-sun:before {
    content: "\e9d5";
}

.icon-contrast:before {
    content: "\e9d6";
}

.icon-brightness-contrast:before {
    content: "\e9d7";
}

.icon-star-empty:before {
    content: "\e9d8";
}

.icon-star-half:before {
    content: "\e9d9";
}

.icon-star-full:before {
    content: "\e9da";
}

.icon-heart:before {
    content: "\e9db";
}

.icon-heart-broken:before {
    content: "\e9dc";
}

.icon-man:before {
    content: "\e9dd";
}

.icon-woman:before {
    content: "\e9de";
}

.icon-man-woman:before {
    content: "\e9df";
}

.icon-happy:before {
    content: "\e9e0";
}

.icon-happy2:before {
    content: "\e9e1";
}

.icon-smile:before {
    content: "\e9e2";
}

.icon-smile2:before {
    content: "\e9e3";
}

.icon-tongue:before {
    content: "\e9e4";
}

.icon-tongue2:before {
    content: "\e9e5";
}

.icon-sad:before {
    content: "\e9e6";
}

.icon-sad2:before {
    content: "\e9e7";
}

.icon-wink:before {
    content: "\e9e8";
}

.icon-wink2:before {
    content: "\e9e9";
}

.icon-grin:before {
    content: "\e9ea";
}

.icon-grin2:before {
    content: "\e9eb";
}

.icon-cool:before {
    content: "\e9ec";
}

.icon-cool2:before {
    content: "\e9ed";
}

.icon-angry:before {
    content: "\e9ee";
}

.icon-angry2:before {
    content: "\e9ef";
}

.icon-evil:before {
    content: "\e9f0";
}

.icon-evil2:before {
    content: "\e9f1";
}

.icon-shocked:before {
    content: "\e9f2";
}

.icon-shocked2:before {
    content: "\e9f3";
}

.icon-baffled:before {
    content: "\e9f4";
}

.icon-baffled2:before {
    content: "\e9f5";
}

.icon-confused:before {
    content: "\e9f6";
}

.icon-confused2:before {
    content: "\e9f7";
}

.icon-neutral:before {
    content: "\e9f8";
}

.icon-neutral2:before {
    content: "\e9f9";
}

.icon-hipster:before {
    content: "\e9fa";
}

.icon-hipster2:before {
    content: "\e9fb";
}

.icon-wondering:before {
    content: "\e9fc";
}

.icon-wondering2:before {
    content: "\e9fd";
}

.icon-sleepy:before {
    content: "\e9fe";
}

.icon-sleepy2:before {
    content: "\e9ff";
}

.icon-frustrated:before {
    content: "\ea00";
}

.icon-frustrated2:before {
    content: "\ea01";
}

.icon-crying:before {
    content: "\ea02";
}

.icon-crying2:before {
    content: "\ea03";
}

.icon-point-up:before {
    content: "\ea04";
}

.icon-point-right:before {
    content: "\ea05";
}

.icon-point-down:before {
    content: "\ea06";
}

.icon-point-left:before {
    content: "\ea07";
}

.icon-warning:before {
    content: "\ea08";
}

.icon-notification:before {
    content: "\ea09";
}

.icon-question:before {
    content: "\ea0a";
}

.icon-plus:before {
    content: "\ea0b";
}

.icon-minus:before {
    content: "\ea0c";
}

.icon-info:before {
    content: "\ea0d";
}

.icon-cancel-circle:before {
    content: "\ea0e";
}

.icon-blocked:before {
    content: "\ea0f";
}

.icon-cross:before {
    content: "\ea10";
}

.icon-checkmark:before {
    content: "\ea11";
}

.icon-checkmark2:before {
    content: "\ea12";
}

.icon-spell-check:before {
    content: "\ea13";
}

.icon-enter:before {
    content: "\ea14";
}

.icon-exit:before {
    content: "\ea15";
}

.icon-play2:before {
    content: "\ea16";
}

.icon-pause:before {
    content: "\ea17";
}

.icon-stop:before {
    content: "\ea18";
}

.icon-previous:before {
    content: "\ea19";
}

.icon-next:before {
    content: "\ea1a";
}

.icon-backward:before {
    content: "\ea1b";
}

.icon-forward2:before {
    content: "\ea1c";
}

.icon-play3:before {
    content: "\ea1d";
}

.icon-pause2:before {
    content: "\ea1e";
}

.icon-stop2:before {
    content: "\ea1f";
}

.icon-backward2:before {
    content: "\ea20";
}

.icon-forward3:before {
    content: "\ea21";
}

.icon-first:before {
    content: "\ea22";
}

.icon-last:before {
    content: "\ea23";
}

.icon-previous2:before {
    content: "\ea24";
}

.icon-next2:before {
    content: "\ea25";
}

.icon-eject:before {
    content: "\ea26";
}

.icon-volume-high:before {
    content: "\ea27";
}

.icon-volume-medium:before {
    content: "\ea28";
}

.icon-volume-low:before {
    content: "\ea29";
}

.icon-volume-mute:before {
    content: "\ea2a";
}

.icon-volume-mute2:before {
    content: "\ea2b";
}

.icon-volume-increase:before {
    content: "\ea2c";
}

.icon-volume-decrease:before {
    content: "\ea2d";
}

.icon-loop:before {
    content: "\ea2e";
}

.icon-loop2:before {
    content: "\ea2f";
}

.icon-infinite:before {
    content: "\ea30";
}

.icon-shuffle:before {
    content: "\ea31";
}

.icon-arrow-up-left:before {
    content: "\ea32";
}

.icon-arrow-up:before {
    content: "\ea33";
}

.icon-arrow-up-right:before {
    content: "\ea34";
}

.icon-arrow-right:before {
    content: "\ea35";
}

.icon-arrow-down-right:before {
    content: "\ea36";
}

.icon-arrow-down:before {
    content: "\ea37";
}

.icon-arrow-down-left:before {
    content: "\ea38";
}

.icon-arrow-left:before {
    content: "\ea39";
}

.icon-arrow-up-left2:before {
    content: "\ea3a";
}

.icon-arrow-up2:before {
    content: "\ea3b";
}

.icon-arrow-up-right2:before {
    content: "\ea3c";
}

.icon-arrow-right2:before {
    content: "\ea3d";
}

.icon-arrow-down-right2:before {
    content: "\ea3e";
}

.icon-arrow-down2:before {
    content: "\ea3f";
}

.icon-arrow-left2:before {
    content: "\ea40";
}

.icon-arrow-down-left2:before {
    content: "\ea41";
}

.icon-circle-up:before {
    content: "\ea42";
}

.icon-circle-right:before {
    content: "\ea43";
}

.icon-circle-down:before {
    content: "\ea44";
}

.icon-circle-left:before {
    content: "\ea45";
}

.icon-tab:before {
    content: "\ea46";
}

.icon-move-up:before {
    content: "\ea47";
}

.icon-move-down:before {
    content: "\ea48";
}

.icon-sort-alpha-asc:before {
    content: "\ea49";
}

.icon-sort-alpha-desc:before {
    content: "\ea4a";
}

.icon-sort-numeric-asc:before {
    content: "\ea4b";
}

.icon-sort-numberic-desc:before {
    content: "\ea4c";
}

.icon-sort-amount-asc:before {
    content: "\ea4d";
}

.icon-sort-amount-desc:before {
    content: "\ea4e";
}

.icon-command:before {
    content: "\ea4f";
}

.icon-shift:before {
    content: "\ea50";
}

.icon-ctrl:before {
    content: "\ea51";
}

.icon-opt:before {
    content: "\ea52";
}

.icon-checkbox-checked:before {
    content: "\ea53";
}

.icon-checkbox-unchecked:before {
    content: "\ea54";
}

.icon-radio-checked:before {
    content: "\ea55";
}

.icon-radio-checked2:before {
    content: "\ea56";
}

.icon-radio-unchecked:before {
    content: "\ea57";
}

.icon-crop:before {
    content: "\ea58";
}

.icon-make-group:before {
    content: "\ea59";
}

.icon-ungroup:before {
    content: "\ea5a";
}

.icon-scissors:before {
    content: "\ea5b";
}

.icon-filter:before {
    content: "\ea5c";
}

.icon-font:before {
    content: "\ea5d";
}

.icon-ligature:before {
    content: "\ea5e";
}

.icon-ligature2:before {
    content: "\ea5f";
}

.icon-text-height:before {
    content: "\ea60";
}

.icon-text-width:before {
    content: "\ea61";
}

.icon-font-size:before {
    content: "\ea62";
}

.icon-bold:before {
    content: "\ea63";
}

.icon-underline:before {
    content: "\ea64";
}

.icon-italic:before {
    content: "\ea65";
}

.icon-strikethrough:before {
    content: "\ea66";
}

.icon-omega:before {
    content: "\ea67";
}

.icon-sigma:before {
    content: "\ea68";
}

.icon-page-break:before {
    content: "\ea69";
}

.icon-superscript:before {
    content: "\ea6a";
}

.icon-subscript:before {
    content: "\ea6b";
}

.icon-superscript2:before {
    content: "\ea6c";
}

.icon-subscript2:before {
    content: "\ea6d";
}

.icon-text-color:before {
    content: "\ea6e";
}

.icon-pagebreak:before {
    content: "\ea6f";
}

.icon-clear-formatting:before {
    content: "\ea70";
}

.icon-table:before {
    content: "\ea71";
}

.icon-table2:before {
    content: "\ea72";
}

.icon-insert-template:before {
    content: "\ea73";
}

.icon-pilcrow:before {
    content: "\ea74";
}

.icon-ltr:before {
    content: "\ea75";
}

.icon-rtl:before {
    content: "\ea76";
}

.icon-section:before {
    content: "\ea77";
}

.icon-paragraph-left:before {
    content: "\ea78";
}

.icon-paragraph-center:before {
    content: "\ea79";
}

.icon-paragraph-right:before {
    content: "\ea7a";
}

.icon-paragraph-justify:before {
    content: "\ea7b";
}

.icon-indent-increase:before {
    content: "\ea7c";
}

.icon-indent-decrease:before {
    content: "\ea7d";
}

.icon-share:before {
    content: "\ea7e";
}

.icon-new-tab:before {
    content: "\ea7f";
}

.icon-embed:before {
    content: "\ea80";
}

.icon-embed2:before {
    content: "\ea81";
}

.icon-terminal:before {
    content: "\ea82";
}

.icon-share2:before {
    content: "\ea83";
}

.icon-mail:before {
    content: "\ea84";
}

.icon-mail2:before {
    content: "\ea85";
}

.icon-mail3:before {
    content: "\ea86";
}

.icon-mail4:before {
    content: "\ea87";
}

.icon-amazon:before {
    content: "\ea88";
}

.icon-google:before {
    content: "\ea89";
}

.icon-google2:before {
    content: "\ea8a";
}

.icon-google3:before {
    content: "\ea8b";
}

.icon-google-plus:before {
    content: "\ea8c";
}

.icon-google-plus2:before {
    content: "\ea8d";
}

.icon-google-plus3:before {
    content: "\ea8e";
}

.icon-hangouts:before {
    content: "\ea8f";
}

.icon-google-drive:before {
    content: "\ea90";
}

.icon-facebook:before {
    content: "\ea91";
}

.icon-facebook2:before {
    content: "\ea92";
}

.icon-instagram:before {
    content: "\ea93";
}

.icon-whatsapp:before {
    content: "\ea94";
}

.icon-spotify:before {
    content: "\ea95";
}

.icon-telegram:before {
    content: "\ea96";
}

.icon-twitter:before {
    content: "\ea97";
}

.icon-vine:before {
    content: "\ea98";
}

.icon-vk:before {
    content: "\ea99";
}

.icon-renren:before {
    content: "\ea9a";
}

.icon-sina-weibo:before {
    content: "\ea9b";
}

.icon-rss:before {
    content: "\ea9c";
}

.icon-rss2:before {
    content: "\ea9d";
}

.icon-youtube:before {
    content: "\ea9e";
}

.icon-youtube2:before {
    content: "\ea9f";
}

.icon-twitch:before {
    content: "\eaa0";
}

.icon-vimeo:before {
    content: "\eaa1";
}

.icon-vimeo2:before {
    content: "\eaa2";
}

.icon-lanyrd:before {
    content: "\eaa3";
}

.icon-flickr:before {
    content: "\eaa4";
}

.icon-flickr2:before {
    content: "\eaa5";
}

.icon-flickr3:before {
    content: "\eaa6";
}

.icon-flickr4:before {
    content: "\eaa7";
}

.icon-dribbble:before {
    content: "\eaa8";
}

.icon-behance:before {
    content: "\eaa9";
}

.icon-behance2:before {
    content: "\eaaa";
}

.icon-deviantart:before {
    content: "\eaab";
}

.icon-500px:before {
    content: "\eaac";
}

.icon-steam:before {
    content: "\eaad";
}

.icon-steam2:before {
    content: "\eaae";
}

.icon-dropbox:before {
    content: "\eaaf";
}

.icon-onedrive:before {
    content: "\eab0";
}

.icon-github:before {
    content: "\eab1";
}

.icon-npm:before {
    content: "\eab2";
}

.icon-basecamp:before {
    content: "\eab3";
}

.icon-trello:before {
    content: "\eab4";
}

.icon-wordpress:before {
    content: "\eab5";
}

.icon-joomla:before {
    content: "\eab6";
}

.icon-ello:before {
    content: "\eab7";
}

.icon-blogger:before {
    content: "\eab8";
}

.icon-blogger2:before {
    content: "\eab9";
}

.icon-tumblr:before {
    content: "\eaba";
}

.icon-tumblr2:before {
    content: "\eabb";
}

.icon-yahoo:before {
    content: "\eabc";
}

.icon-yahoo2:before {
    content: "\eabd";
}

.icon-tux:before {
    content: "\eabe";
}

.icon-appleinc:before {
    content: "\eabf";
}

.icon-finder:before {
    content: "\eac0";
}

.icon-android:before {
    content: "\eac1";
}

.icon-windows:before {
    content: "\eac2";
}

.icon-windows8:before {
    content: "\eac3";
}

.icon-soundcloud:before {
    content: "\eac4";
}

.icon-soundcloud2:before {
    content: "\eac5";
}

.icon-skype:before {
    content: "\eac6";
}

.icon-reddit:before {
    content: "\eac7";
}

.icon-hackernews:before {
    content: "\eac8";
}

.icon-wikipedia:before {
    content: "\eac9";
}

.icon-linkedin:before {
    content: "\eaca";
}

.icon-linkedin2:before {
    content: "\eacb";
}

.icon-lastfm:before {
    content: "\eacc";
}

.icon-lastfm2:before {
    content: "\eacd";
}

.icon-delicious:before {
    content: "\eace";
}

.icon-stumbleupon:before {
    content: "\eacf";
}

.icon-stumbleupon2:before {
    content: "\ead0";
}

.icon-stackoverflow:before {
    content: "\ead1";
}

.icon-pinterest:before {
    content: "\ead2";
}

.icon-pinterest2:before {
    content: "\ead3";
}

.icon-xing:before {
    content: "\ead4";
}

.icon-xing2:before {
    content: "\ead5";
}

.icon-flattr:before {
    content: "\ead6";
}

.icon-foursquare:before {
    content: "\ead7";
}

.icon-yelp:before {
    content: "\ead8";
}

.icon-paypal:before {
    content: "\ead9";
}

.icon-chrome:before {
    content: "\eada";
}

.icon-firefox:before {
    content: "\eadb";
}

.icon-IE:before {
    content: "\eadc";
}

.icon-edge:before {
    content: "\eadd";
}

.icon-safari:before {
    content: "\eade";
}

.icon-opera:before {
    content: "\eadf";
}

.icon-file-pdf:before {
    content: "\eae0";
}

.icon-file-openoffice:before {
    content: "\eae1";
}

.icon-file-word:before {
    content: "\eae2";
}

.icon-file-excel:before {
    content: "\eae3";
}

.icon-libreoffice:before {
    content: "\eae4";
}

.icon-html-five:before {
    content: "\eae5";
}

.icon-html-five2:before {
    content: "\eae6";
}

.icon-css3:before {
    content: "\eae7";
}

.icon-git:before {
    content: "\eae8";
}

.icon-codepen:before {
    content: "\eae9";
}

.icon-svg:before {
    content: "\eaea";
}

.icon-IcoMoon:before {
    content: "\eaeb";
}

.icon-airplay:before {
    content: "\eaec";
}

.icon-alert-circle:before {
    content: "\eaed";
}

.icon-alert-octagon:before {
    content: "\eaee";
}

.icon-alert-triangle:before {
    content: "\eaef";
}

.icon-align-center:before {
    content: "\eaf0";
}

.icon-align-justify:before {
    content: "\eaf1";
}

.icon-align-left:before {
    content: "\eaf2";
}

.icon-align-right:before {
    content: "\eaf3";
}

.icon-anchor:before {
    content: "\eaf4";
}

.icon-aperture:before {
    content: "\eaf5";
}

.icon-archive:before {
    content: "\eaf6";
}

.icon-arrow-down1:before {
    content: "\eaf7";
}

.icon-arrow-down-circle:before {
    content: "\eaf8";
}

.icon-arrow-down-left1:before {
    content: "\eaf9";
}

.icon-arrow-down-right1:before {
    content: "\eafa";
}

.icon-arrow-left1:before {
    content: "\eafb";
}

.icon-arrow-left-circle:before {
    content: "\eafc";
}

.icon-arrow-right1:before {
    content: "\eafd";
}

.icon-arrow-right-circle:before {
    content: "\eafe";
}

.icon-arrow-up1:before {
    content: "\eaff";
}

.icon-arrow-up-circle:before {
    content: "\eb00";
}

.icon-arrow-up-left1:before {
    content: "\eb01";
}

.icon-arrow-up-right1:before {
    content: "\eb02";
}

.icon-at-sign:before {
    content: "\eb03";
}

.icon-award:before {
    content: "\eb04";
}

.icon-bar-chart:before {
    content: "\eb05";
}

.icon-bar-chart-2:before {
    content: "\eb06";
}

.icon-battery:before {
    content: "\eb07";
}

.icon-battery-charging:before {
    content: "\eb08";
}

.icon-bell1:before {
    content: "\eb09";
}

.icon-bell-off:before {
    content: "\eb0a";
}

.icon-bluetooth:before {
    content: "\eb0b";
}

.icon-bold1:before {
    content: "\eb0c";
}

.icon-book1:before {
    content: "\eb0d";
}

.icon-book-open:before {
    content: "\eb0e";
}

.icon-bookmark1:before {
    content: "\eb0f";
}

.icon-box:before {
    content: "\eb10";
}

.icon-briefcase1:before {
    content: "\eb11";
}

.icon-calendar1:before {
    content: "\eb12";
}

.icon-camera1:before {
    content: "\eb13";
}

.icon-camera-off:before {
    content: "\eb14";
}

.icon-cast:before {
    content: "\eb15";
}

.icon-check:before {
    content: "\eb16";
}

.icon-check-circle:before {
    content: "\eb17";
}

.icon-check-square:before {
    content: "\eb18";
}

.icon-chevron-down:before {
    content: "\eb19";
}

.icon-chevron-left:before {
    content: "\eb1a";
}

.icon-chevron-right:before {
    content: "\eb1b";
}

.icon-chevron-up:before {
    content: "\eb1c";
}

.icon-chevrons-down:before {
    content: "\eb1d";
}

.icon-chevrons-left:before {
    content: "\eb1e";
}

.icon-chevrons-right:before {
    content: "\eb1f";
}

.icon-chevrons-up:before {
    content: "\eb20";
}

.icon-chrome1:before {
    content: "\eb21";
}

.icon-circle:before {
    content: "\eb22";
}

.icon-clipboard1:before {
    content: "\eb23";
}

.icon-clock1:before {
    content: "\eb24";
}

.icon-cloud1:before {
    content: "\eb25";
}

.icon-cloud-drizzle:before {
    content: "\eb26";
}

.icon-cloud-lightning:before {
    content: "\eb27";
}

.icon-cloud-off:before {
    content: "\eb28";
}

.icon-cloud-rain:before {
    content: "\eb29";
}

.icon-cloud-snow:before {
    content: "\eb2a";
}

.icon-code:before {
    content: "\eb2b";
}

.icon-codepen1:before {
    content: "\eb2c";
}

.icon-codesandbox:before {
    content: "\eb2d";
}

.icon-coffee:before {
    content: "\eb2e";
}

.icon-columns:before {
    content: "\eb2f";
}

.icon-command1:before {
    content: "\eb30";
}

.icon-compass1:before {
    content: "\eb31";
}

.icon-copy1:before {
    content: "\eb32";
}

.icon-corner-down-left:before {
    content: "\eb33";
}

.icon-corner-down-right:before {
    content: "\eb34";
}

.icon-corner-left-down:before {
    content: "\eb35";
}

.icon-corner-left-up:before {
    content: "\eb36";
}

.icon-corner-right-down:before {
    content: "\eb37";
}

.icon-corner-right-up:before {
    content: "\eb38";
}

.icon-corner-up-left:before {
    content: "\eb39";
}

.icon-corner-up-right:before {
    content: "\eb3a";
}

.icon-cpu:before {
    content: "\eb3b";
}

.icon-credit-card1:before {
    content: "\eb3c";
}

.icon-crop1:before {
    content: "\eb3d";
}

.icon-crosshair:before {
    content: "\eb3e";
}

.icon-database1:before {
    content: "\eb3f";
}

.icon-delete:before {
    content: "\eb40";
}

.icon-disc:before {
    content: "\eb41";
}

.icon-dollar-sign:before {
    content: "\eb42";
}

.icon-download1:before {
    content: "\eb43";
}

.icon-download-cloud:before {
    content: "\eb44";
}

.icon-droplet1:before {
    content: "\eb45";
}

.icon-edit:before {
    content: "\eb46";
}

.icon-edit-2:before {
    content: "\eb47";
}

.icon-edit-3:before {
    content: "\eb48";
}

.icon-external-link:before {
    content: "\eb49";
}

.icon-eye1:before {
    content: "\eb4a";
}

.icon-eye-off:before {
    content: "\eb4b";
}

.icon-facebook1:before {
    content: "\eb4c";
}

.icon-fast-forward:before {
    content: "\eb4d";
}

.icon-feather:before {
    content: "\eb4e";
}

.icon-figma:before {
    content: "\eb4f";
}

.icon-file:before {
    content: "\eb50";
}

.icon-file-minus:before {
    content: "\eb51";
}

.icon-file-plus:before {
    content: "\eb52";
}

.icon-file-text1:before {
    content: "\eb53";
}

.icon-film1:before {
    content: "\eb54";
}

.icon-filter1:before {
    content: "\eb55";
}

.icon-flag1:before {
    content: "\eb56";
}

.icon-folder1:before {
    content: "\eb57";
}

.icon-folder-minus1:before {
    content: "\eb58";
}

.icon-folder-plus1:before {
    content: "\eb59";
}

.icon-framer:before {
    content: "\eb5a";
}

.icon-frown:before {
    content: "\eb5b";
}

.icon-gift1:before {
    content: "\eb5c";
}

.icon-git-branch:before {
    content: "\eb5d";
}

.icon-git-commit:before {
    content: "\eb5e";
}

.icon-git-merge:before {
    content: "\eb5f";
}

.icon-git-pull-request:before {
    content: "\eb60";
}

.icon-github1:before {
    content: "\eb61";
}

.icon-gitlab:before {
    content: "\eb62";
}

.icon-globe:before {
    content: "\eb63";
}

.icon-grid:before {
    content: "\eb64";
}

.icon-hard-drive:before {
    content: "\eb65";
}

.icon-hash:before {
    content: "\eb66";
}

.icon-headphones1:before {
    content: "\eb67";
}

.icon-heart1:before {
    content: "\eb68";
}

.icon-help-circle:before {
    content: "\eb69";
}

.icon-hexagon:before {
    content: "\eb6a";
}

.icon-home1:before {
    content: "\eb6b";
}

.icon-image1:before {
    content: "\eb6c";
}

.icon-inbox:before {
    content: "\eb6d";
}

.icon-info1:before {
    content: "\eb6e";
}

.icon-instagram1:before {
    content: "\eb6f";
}

.icon-italic1:before {
    content: "\eb70";
}

.icon-key1:before {
    content: "\eb71";
}

.icon-layers:before {
    content: "\eb72";
}

.icon-layout:before {
    content: "\eb73";
}

.icon-life-buoy:before {
    content: "\eb74";
}

.icon-link1:before {
    content: "\eb75";
}

.icon-link-2:before {
    content: "\eb76";
}

.icon-linkedin1:before {
    content: "\eb77";
}

.icon-list1:before {
    content: "\eb78";
}

.icon-loader:before {
    content: "\eb79";
}

.icon-lock1:before {
    content: "\eb7a";
}

.icon-log-in:before {
    content: "\eb7b";
}

.icon-log-out:before {
    content: "\eb7c";
}

.icon-mail1:before {
    content: "\eb7d";
}

.icon-map1:before {
    content: "\eb7e";
}

.icon-map-pin:before {
    content: "\eb7f";
}

.icon-maximize:before {
    content: "\eb80";
}

.icon-maximize-2:before {
    content: "\eb81";
}

.icon-meh:before {
    content: "\eb82";
}

.icon-menu1:before {
    content: "\eb83";
}

.icon-message-circle:before {
    content: "\eb84";
}

.icon-message-square:before {
    content: "\eb85";
}

.icon-mic1:before {
    content: "\eb86";
}

.icon-mic-off:before {
    content: "\eb87";
}

.icon-minimize:before {
    content: "\eb88";
}

.icon-minimize-2:before {
    content: "\eb89";
}

.icon-minus1:before {
    content: "\eb8a";
}

.icon-minus-circle:before {
    content: "\eb8b";
}

.icon-minus-square:before {
    content: "\eb8c";
}

.icon-monitor:before {
    content: "\eb8d";
}

.icon-moon:before {
    content: "\eb8e";
}

.icon-more-horizontal:before {
    content: "\eb8f";
}

.icon-more-vertical:before {
    content: "\eb90";
}

.icon-mouse-pointer:before {
    content: "\eb91";
}

.icon-move:before {
    content: "\eb92";
}

.icon-music1:before {
    content: "\eb93";
}

.icon-navigation:before {
    content: "\eb94";
}

.icon-navigation-2:before {
    content: "\eb95";
}

.icon-octagon:before {
    content: "\eb96";
}

.icon-package:before {
    content: "\eb97";
}

.icon-paperclip:before {
    content: "\eb98";
}

.icon-pause1:before {
    content: "\eb99";
}

.icon-pause-circle:before {
    content: "\eb9a";
}

.icon-pen-tool:before {
    content: "\eb9b";
}

.icon-percent:before {
    content: "\eb9c";
}

.icon-phone1:before {
    content: "\eb9d";
}

.icon-phone-call:before {
    content: "\eb9e";
}

.icon-phone-forwarded:before {
    content: "\eb9f";
}

.icon-phone-incoming:before {
    content: "\eba0";
}

.icon-phone-missed:before {
    content: "\eba1";
}

.icon-phone-off:before {
    content: "\eba2";
}

.icon-phone-outgoing:before {
    content: "\eba3";
}

.icon-pie-chart1:before {
    content: "\eba4";
}

.icon-play1:before {
    content: "\eba5";
}

.icon-play-circle:before {
    content: "\eba6";
}

.icon-plus1:before {
    content: "\eba7";
}

.icon-plus-circle:before {
    content: "\eba8";
}

.icon-plus-square:before {
    content: "\eba9";
}

.icon-pocket:before {
    content: "\ebaa";
}

.icon-power1:before {
    content: "\ebab";
}

.icon-printer1:before {
    content: "\ebac";
}

.icon-radio:before {
    content: "\ebad";
}

.icon-refresh-ccw:before {
    content: "\ebae";
}

.icon-refresh-cw:before {
    content: "\ebaf";
}

.icon-repeat:before {
    content: "\ebb0";
}

.icon-rewind:before {
    content: "\ebb1";
}

.icon-rotate-ccw:before {
    content: "\ebb2";
}

.icon-rotate-cw:before {
    content: "\ebb3";
}

.icon-rss1:before {
    content: "\ebb4";
}

.icon-save:before {
    content: "\ebb5";
}

.icon-scissors1:before {
    content: "\ebb6";
}

.icon-search1:before {
    content: "\ebb7";
}

.icon-send:before {
    content: "\ebb8";
}

.icon-server:before {
    content: "\ebb9";
}

.icon-settings:before {
    content: "\ebba";
}

.icon-share1:before {
    content: "\ebbb";
}

.icon-share-2:before {
    content: "\ebbc";
}

.icon-shield1:before {
    content: "\ebbd";
}

.icon-shield-off:before {
    content: "\ebbe";
}

.icon-shopping-bag:before {
    content: "\ebbf";
}

.icon-shopping-cart:before {
    content: "\ebc0";
}

.icon-shuffle1:before {
    content: "\ebc1";
}

.icon-sidebar:before {
    content: "\ebc2";
}

.icon-skip-back:before {
    content: "\ebc3";
}

.icon-skip-forward:before {
    content: "\ebc4";
}

.icon-slack:before {
    content: "\ebc5";
}

.icon-slash:before {
    content: "\ebc6";
}

.icon-sliders:before {
    content: "\ebc7";
}

.icon-smartphone:before {
    content: "\ebc8";
}

.icon-smile1:before {
    content: "\ebc9";
}

.icon-speaker:before {
    content: "\ebca";
}

.icon-square:before {
    content: "\ebcb";
}

.icon-star:before {
    content: "\ebcc";
}

.icon-stop-circle:before {
    content: "\ebcd";
}

.icon-sun1:before {
    content: "\ebce";
}

.icon-sunrise:before {
    content: "\ebcf";
}

.icon-sunset:before {
    content: "\ebd0";
}

.icon-tablet1:before {
    content: "\ebd1";
}

.icon-tag:before {
    content: "\ebd2";
}

.icon-target1:before {
    content: "\ebd3";
}

.icon-terminal1:before {
    content: "\ebd4";
}

.icon-thermometer:before {
    content: "\ebd5";
}

.icon-thumbs-down:before {
    content: "\ebd6";
}

.icon-thumbs-up:before {
    content: "\ebd7";
}

.icon-toggle-left:before {
    content: "\ebd8";
}

.icon-toggle-right:before {
    content: "\ebd9";
}

.icon-tool:before {
    content: "\ebda";
}

.icon-trash:before {
    content: "\ebdb";
}

.icon-trash-2:before {
    content: "\ebdc";
}

.icon-trello1:before {
    content: "\ebdd";
}

.icon-trending-down:before {
    content: "\ebde";
}

.icon-trending-up:before {
    content: "\ebdf";
}

.icon-triangle:before {
    content: "\ebe0";
}

.icon-truck1:before {
    content: "\ebe1";
}

.icon-tv1:before {
    content: "\ebe2";
}

.icon-twitch1:before {
    content: "\ebe3";
}

.icon-twitter1:before {
    content: "\ebe4";
}

.icon-type:before {
    content: "\ebe5";
}

.icon-umbrella:before {
    content: "\ebe6";
}

.icon-underline1:before {
    content: "\ebe7";
}

.icon-unlock:before {
    content: "\ebe8";
}

.icon-upload1:before {
    content: "\ebe9";
}

.icon-upload-cloud:before {
    content: "\ebea";
}

.icon-user1:before {
    content: "\ebeb";
}

.icon-user-check1:before {
    content: "\ebec";
}

.icon-user-minus1:before {
    content: "\ebed";
}

.icon-user-plus1:before {
    content: "\ebee";
}

.icon-user-x:before {
    content: "\ebef";
}

.icon-users1:before {
    content: "\ebf0";
}

.icon-video:before {
    content: "\ebf1";
}

.icon-video-off:before {
    content: "\ebf2";
}

.icon-voicemail:before {
    content: "\ebf3";
}

.icon-volume:before {
    content: "\ebf4";
}

.icon-volume-1:before {
    content: "\ebf5";
}

.icon-volume-2:before {
    content: "\ebf6";
}

.icon-volume-x:before {
    content: "\ebf7";
}

.icon-watch:before {
    content: "\ebf8";
}

.icon-wifi:before {
    content: "\ebf9";
}

.icon-wifi-off:before {
    content: "\ebfa";
}

.icon-wind:before {
    content: "\ebfb";
}

.icon-x:before {
    content: "\ebfc";
}

.icon-x-circle:before {
    content: "\ebfd";
}

.icon-x-octagon:before {
    content: "\ebfe";
}

.icon-x-square:before {
    content: "\ebff";
}

.icon-youtube1:before {
    content: "\ec00";
}

.icon-zap:before {
    content: "\ec01";
}

.icon-zap-off:before {
    content: "\ec02";
}

.icon-zoom-in1:before {
    content: "\ec03";
}

.icon-zoom-out1:before {
    content: "\ec04";
}
