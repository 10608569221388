.regularPrice {
    text-decoration: line-through;
    color: grey;
}

.price {
    font-size: 24px;
    font-weight: bold;
}

@media (max-width: 640px) {
    .container {
        flex-direction: column;
    }
}
