.container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 64px 0;
    gap: 32px;
}

.content {
    gap: 24px;
}

.container img {
    width: 400px;
    height: auto;
}

.button {
    width: fit-content;
}

@media (max-width: 800px) {
    .container {
        flex-direction: column-reverse;
    }
}
