.container {
    position: relative;
    padding-bottom: 54.25%; /* 16:9 */
    height: 0;
    background-color: black;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 100%;
        max-height: 100vh;
    }
}

.content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.title {
    font-size: 42px;
    font-weight: bold;
    color: white;
    line-height: 1.2;
    padding: 0 5px;
}

.button {
    width: 50%;
    text-align: center;
}

@media (max-width: 1280px) {
    .title,
    .text,
    .button {
        width: 85%;
        text-align: center;
        margin: auto;
    }
}

@media (max-width: 1000px) {
    .image {
        max-height: 56.25vw;
        min-height: 56.25vw;
    }

    .title {
        font-size: 32px;
    }
}

@media (max-width: 640px) {
    .title {
        font-size: 18px;
    }

    .text {
        display: none;
    }

    .button {
        margin-top: 10px;
    }
}
