.MuiSlider-valueLabel {
    position: relative;
}
    .MuiSlider-valueLabel > span {
        -webkit-transform: unset !important;
                transform: unset !important;
        width: unset !important;
        height: unset !important;
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -webkit-box-align: center !important;
            -ms-flex-align: center !important;
                align-items: center !important;
        -webkit-box-pack: center !important;
            -ms-flex-pack: center !important;
                justify-content: center !important;
        background-color: #024b9b !important;
        border-radius: 5px !important;
        padding: 2px 5px !important;
        margin-top: 10px;
    }
    .MuiSlider-valueLabel > span > span {
            -webkit-transform: unset !important;
                    transform: unset !important;
            color: white;
            font-size: 12px;
            font-weight: bold;
        }

.MuiSlider_arrow {
    position: absolute;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    top: -20px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 12px solid #024b9b;
}