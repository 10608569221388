.container {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: var(--zIndex-overlay);
    background-color: var(--color-tertiary-bg);
    overflow: auto;
    transform: translateX(100vw);
    transition: all 0.4s ease-in-out;
    box-sizing: border-box;
}

.containerSmall {
    left: 70%;
}

.containerMedium {
    left: 60%;
}

.containerLarge {
    left: 30%;
}

.opened {
    display: block;
    transform: translateX(0);
}

.logo {
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 40px;
}

.closeIcon {
    z-index: var(--zIndex-overlay);
    position: absolute !important;
    top: 10px;
    right: 20px;
}

.overlay {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 801;
    background-color: rgba(0, 0, 0, 0.4);
}

@media (max-width: 1200px) {
    .containerSmall {
        left: 65%;
    }

    .containerMedium {
        left: 55%;
    }

    .containerLarge {
        left: 25%;
    }
}

@media (max-width: 1000px) {
    .containerSmall {
        left: 55%;
    }

    .containerMedium {
        left: 40%;
    }

    .containerLarge {
        left: 30%;
    }
}

@media (max-width: 768px) {
    .containerSmall {
        left: 40%;
    }

    .containerMedium {
        left: 20%;
    }

    .containerLarge {
        left: 0;
    }
}

@media (max-width: 540px) {
    .containerSmall {
        left: 0;
    }

    .containerMedium {
        left: 0;
    }

    .containerLarge {
        left: 0;
    }
}
