.imgSquare {
    width: 60px;
}

.imgMC {
    width: 80px;
}

.imgVisa {
    width: 110px;
}