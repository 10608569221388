.rightBtn {
    float: right;
}

.label {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 5px;
}

.disabled {
    pointer-events: none;
    opacity: 0.4;
}