.container {
    height: 100px;
}

.option {
    height: 50px;
    text-align: center;
    font-weight: 550;
    line-height: 12px;
}

.textOptions {
    font-size: 12px;
    line-height: 1.2;
}

.optionLabel {
    font-size: 10px;
    color: var(--color-primary-lighter);
}
