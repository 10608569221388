.tag {
    color: rgba(255, 255, 255, 0.90);
    z-index: var(--zIndex-product-card);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    padding: 6px 8px;
    margin-bottom: 10px;
    border-radius: 3px 0 0 3px;
    width: max-content;
    vertical-align: top;
    line-height: 1;
    height: max-content;
    position: relative;
    top: 10px;
}

.sold {
    background-color: var(--color-secondary) !important;
}

.red {
    background-color: red !important;
}
.orange {
    background-color: orange !important;
}
.yellow {
    background-color: yellow !important;
}
.black {
    background-color: black !important;
}
.green {
    background-color: green !important;
}
.blue {
    background-color: blue !important;
}
.pink {
    background-color: pink !important;
}


