.fadeIn {
    animation-name: fadeIn;
    animation-duration: 0.5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes marqueelike {
    0%,
    100% {
        margin-left: 0;
    }
    99.99% {
        margin-left: -100%;
    }
}