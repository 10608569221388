.shopFilterDesktop {
    display: flex;
    width: 107%;
    margin-left: -3%;
}

.shopFiltersMobile {
    display: none;
}

@media (max-width: 960px) {
    .shopFilterDesktop {
        display: none;
    }

    .shopFiltersMobile {
        display: flex;
    }
}

.dialogContent {
    padding: 0 10px;
    width: 500px;
}
