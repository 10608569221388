.container {
    line-height: 1.1;
    font-size: 12px;

}

@media (max-width: 900px) {
    .container {
    }

    .header {
        font-size: 10px;
    }
}