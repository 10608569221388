.container {
    display: flex;
    justify-content: space-between;
}

.label {
    align-self: center;
    white-space: nowrap;
}

.sort {
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 640px) {
    .container {
        flex-direction: column;
        align-items: start;
    }
}

.selected {
    color: red;
}

.button {
    font-size: 16px;
    color: grey;
}

.disabled {
    color: lightgrey;
}
