.container {
    cursor: pointer;
    padding: 3px 20px;
    color: var(--color-top-header-bg) !important;
}

.container_highlighted {
    background-color: var(--color-secondary);
}

.item {
    max-width: 100%;
}

.container:hover {
    background-color: lightgrey;
}