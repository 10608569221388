.container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper .swiper-wrapper {
    overflow: visible !important;
}

@media (max-width: 1280px) {
    .swiper {
        overflow: visible !important;
    }
}
