.light {
    background-color: white;
}

.primary {
    background-color: var(--color-primary);
}

.secondary {
    background-color: var(--color-secondary);
}

.dark {
    background-color: #111;
}