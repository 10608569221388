.title {
    font-size: 16px;
    font-weight: bold;
}

.link {
    font-size: 14px;
    display: block;
    margin-bottom: 10px;
    color: #fff !important;
    white-space: nowrap;
}

.link:hover {
    color: var(--color-secondary) !important;
    text-decoration: none !important;
}

.link:before {
    content: "\0025b8   ";
}

@media (hover) {
    .link:hover {
        text-decoration: underline;
    }
}
