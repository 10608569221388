:root {
    --color-primary: #024b9b;
    --color-primary-darker: #004384;
    --color-primary-lighter: #0363cd;
    --color-secondary: #ffb500;
    --color-tertiary: #ffa34d;
    --color-tertiary-alt: rgba(254, 220, 8, 0.71);
    --color-quaternary: #f67575;
    --color-success: #0ead69;
    --color-inProgress: #ee4266;
    --color-canceled: #6103c2;

    --color-text: #2b3f57;
    --color-text-gray: #516070;
    --color-text-fade: rgba(43, 63, 87, 0.74);
    --color-text-on-primary: #fff;

    --color-primary-bg: #1e1a33;
    --color-tertiary-bg: #fafbfd;
    --color-tertiary-text: #92a2ba;
    --color-secondary-bg: #f2f7fa;
    --color-firstLvl-bg: #f3f5f6;
    --color-firstLvl-bg-dark: #e0e0e0;
    --color-secondLvl-bg: #f5f6fa;
    --color-secondLvl-dark-bg: #dce0e9;
    --color-thirdLvl-bg: #fff;

    --color-warning: var(--color-tertiary);
    --color-error: #ff3e1d;
    --color-delete: #ff3e1d;

    --color-border: #cdd1d7;

    --color-header-bg: var(--color-primary);
    --color-header-font: #fff;
    --color-top-header-bg: #333;
    --color-top-header-font: white;
    --color-menu-header-bg: white;
    --color-menu-header-font: #333;
    --color-footer-bg: var(--color-primary);
    --color-footer-font: #f1f1f1;

    --color-primary-50: #fafcff;
    --color-primary-100: #d5e9ff;
    --color-primary-200: #badbff;
    --color-primary-300: #91bef0;
    --color-primary-400: #3d88da;
    --color-primary-500: #014b9b;
    --color-primary-600: #063e7a;
    --color-primary-700: #083a71;
    --color-primary-800: #022851;
    --color-primary-900: #031d39;
}
