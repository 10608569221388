.container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
    width: 350px;
}

.container h3 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.imageContainer {
    width: 100%;
    height: 240px;
    border: 0.3px solid lightgrey;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 24px;
}

.containerWithLink {
    cursor: pointer;
}

.containerWithLink:hover .image,
.imagePlaceholder {
    opacity: 0.3;
}

.containerWithLink:hover .openInNew {
    opacity: 1;
}

.openInNew {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.openInNew > svg {
    width: 64px;
    height: 64px;
    color: var(--color-primary);
}

.imageContainer img {
    width: auto;
    height: 100%;
}

.imagePlaceholder {
    background: lightgrey;
    width: 100%;
    height: 100%;
}

.compareList {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.compareLine {
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: var(--color-text-gray);
}

.compareLine > span {
    font-size: 14px;
}

.removeProduct {
    color: var(--color-warning);
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
}

@media (max-width: 640px) {
    .container {
        width: 280px;
        padding: 16px;
    }

    .compareLine {
        font-size: 14px;
    }
}
