.title {
    color: var(--color-font);
    font-size: 12px;
}

.container {
    background-color: var(--color-tertiary-bg);
}

.button {
    font-size: 10px;
    text-transform: none !important;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
}
