.container {
    width: 100% !important;
    margin: 0 !important;
    border-radius: var(--border-radius);
    background-color: white;
}

.head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
}

@media (max-width: 767px) {
    .head {
        flex-direction: column;
        gap: 16px;
    }
}
