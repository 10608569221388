.image {
    display: block;
    max-width: 100%;
    width: 100%;
}

.container {
    position: relative;
}

.content {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
}

.content:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.65);
}

.button {
    font-weight: bold !important;
    color: white !important;
    border: 2px solid white !important;
}

@media (max-width: 960px) {
    .phoneDisplay {
        display: none;
    }
}

@media (min-width: 960px) {
    .hiddenDisplay {
        display: none;
    }
}
