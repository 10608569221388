.container {
    flex: 1;
    justify-content: center;
    height: 100%;
}

.innerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.link {
    color: white !important;
    font-size: 24px;
    margin: 0 10px;
    transition: all 0.3s;
}

@media (hover) {
    .link:hover {
        transform: scale(1.1);
    }
}

@media (max-width: 1250px) {
    .container {
        flex: 1;
        border: none;
        text-align: center;
        padding-left: 10px;
    }
}

@media (max-width: 800px) {
    .container {
        flex-shrink: 0;
        margin: 0;
        padding: 0;
        justify-content: center;
    }

    .innerContainer {
        width: auto;
        justify-content: center;
    }
}
