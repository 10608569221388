@import "../../../_css/variables/variables.css";
@import "../../../_css/utils/transitions.css";

.heart {
    font-size: 25px;
    color: red !important;
    animation: beat 2.5s infinite alternate;
    transform-origin: center;
}

.giftCardLink:hover .heart {
    animation: beat 1.5s infinite;
}

.giftCardLink {
    padding: 6%;
    width: 120%;
}

.initiation {
    cursor: pointer;
    color: var(--color-primary);
    font-weight: 600;
}



.text {
    color: var(--color-primary);
    white-space: nowrap;
}

@keyframes beat {
    0% {
        transform: scale(0.8);
    }
    5% {
        transform: scale(0.9);
    }
    10% {
        transform: scale(0.8);
    }
    15% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.8);
    }
    100% {
        transform: scale(0.8);
    }
}

.header {
    z-index: var(--zIndex-header);
}

.icon {
    color: var(--color-primary) !important;
}

.topContainer,
.topWrapper {
    height: 40px;
    display: flex;
}

.topContainerInner {
    height: 100%;
}

.topWrapper {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    z-index: var(--zIndex-product-card);
    background-color: var(--color-top-header-bg);
    color: var(--color-top-header-font);
}

.bottomContainer,
.bottomWrapper {
    height: 60px;
}

.bottomWrapper {
    top: 40px;
    left: 0;
    right: 0;
    z-index: var(--zIndex-navBar);
    background-color: var(--color-header-bg);
    color: var(--color-header-font);
}

.menuContainer,
.menuWrapper {
    height: 60px;
}

.menuContainer {
    margin-left: -20px;
}

.menuWrapper {
    top: 100px;
    left: 0;
    right: 0;
    z-index: var(--zIndex-navBar);
    background-color: var(--color-menu-header-bg);
    color: var(--color-menu-header-font);
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.logo {
    font-size: 28px;
    font-weight: 900;
    color: var(--color-primary);
    text-decoration: none;
    margin-right: 20px;
}

.headerLogo {
    height: 40px;
}

.highlight {
    position: relative;
    overflow: hidden;
}

.menuUserContainer {
    z-index: var(--zIndex-navBar);
}

.highlight p {
    display: inline-block;
    a {
        color: var(--color-secondary);
    }
}

@media (max-width: 1280px) {
    .phoneNumber {
        display: none;
    }
}

@media (max-width: 1000px) {
    .menuWrapper,
    .menuUserContainer {
        display: none;
    }

    .highlight p {
        font-size: 13px;
        line-height: 1.1;
    }
}
