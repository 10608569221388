.cross {
    position: absolute;
    right: 0;
}

.crossPlaceHolderSmall {
    padding-top: 30px;
}

.crossPlaceHolderLarge {
    padding-top: 50px;
}
