.imgContainer {
    display: inline-block;
}

.container {
    background-color: white;
    font-size: 14px;
    padding-left: 32px;
}

.images {
    display: flex;
    gap: 48px;
}

.height {
    top: 40%;
    left: -8%;
}

.length {
    top: 87%;
    left: 57%;
}

.foldedHeight {
    top: 60%;
    left: -8%;
}

.img {
    height: 330px;
    object-fit: contain;
}

.eucContainer {
    display: flex;
    gap: 48px;
    flex-wrap: wrap;
}

.widthContainer {
    width: fit-content;
}

@media (max-width: 600px) {
    .images {
        flex-direction: column;
        gap: 12px;
    }
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .eucContainer {
        justify-content: center;
        gap: 12px;
    }
}
