.container {
    position: relative;
    width: 100%;
    /*height: auto;*/
    /*height of homepage banner*/
    height: 600px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    overflow: hidden;
    a {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.placeholder {
    height: 60px;
    background-color: var(--color-firstLvl-bg);
}

.content {
    height: 100%;
}

.leftSide {
    width: 50%;
    gap: 2rem;
}

.title {
    font-size: 2.5rem;
}

/*@media (max-width: 1500px) {*/
/*    .container {*/
/*        min-height: 480px;*/
/*    }*/
/*}*/


/*@media (max-width: 1280px) {*/
/*    .container {*/
/*        min-height: 400px;*/
/*    }*/
/*}*/

@media (max-width: 1000px) {
    .leftSide {
        width: 75%;
        gap: 1.5rem;
    }
}

/*@media (max-width: 600px) {*/
/*    .container {*/
/*        min-height: 190px;*/
/*    }*/
/*}*/

/*@media (max-width: 500px) {*/
/*    .container {*/
/*        min-height: 130px;*/
/*    }*/
/*}*/
