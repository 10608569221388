.container {
    position: relative;
}

.background {
}

.image {
    max-height: calc(56.25vw - 160px);
    min-height: calc(56.25vw - 160px);
    object-fit: cover;
    position: center;
    width: 100%;
}

.content {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
}

.title,
.text,
.button {
    margin-left: 25%;
    width: 50%;
    text-align: center;
}

.title {
    font-size: 42px;
    font-weight: bold;
    color: white;
    padding: 0 5px;
    line-height: 1.2;
}

.text {
    color: white;
    padding: 0 5px;
}

@media (max-width: 1280px) {
    .title,
    .text,
    .button {
        width: 75%;
        text-align: center;
        margin-left: 0;
    }
}

@media (max-width: 1000px) {
    .image {
        max-height: 56.25vw;
        min-height: 56.25vw;
    }

    .title {
        font-size: 32px;
    }
}

@media (max-width: 640px) {
    .title {
        font-size: 18px;
    }

    .text {
        display: none;
    }

    .button {
        margin-top: 10px;
    }
}