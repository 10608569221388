.container {
    display: flex;
    flex-direction: column;
    gap: 64px;
}

.productCategories {
    position: relative;
    margin-top: -50px;
    gap: 64px;
    flex-wrap: wrap;
    justify-content: center;
}

.storeSectionContainer {
    background-color: white;
}

.bestSellers {
    gap: 32px;
}

.whiteContainer {
    background: white;
}

.spaceY {
    margin-top: 64px;
}

@media (max-width: 780px) {
    .productCategories {
        margin-top: -25px;
    }
}

@media (max-width: 480px) {
    .productCategories {
        margin-top: -30px;
    }
}
