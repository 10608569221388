.container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
    width: 350px;
    position: relative;
}

.addProduct {
    text-align: center;
    width: 100%;
    color: var(--color-primary);
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    padding: 0 32px;
}

.addIcon {
    display: none;
}

.products {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.product {
    display: flex;
    gap: 8px;
    cursor: pointer;
}

.product:hover .name {
    text-decoration: underline;
}

.name {
    white-space: nowrap;
}

.image {
    width: 32px;
    height: 32px;
}

@media (max-width: 640px) {
    .container {
        width: fit-content;
        padding: 24px 0;
    }

    .addProduct {
        text-align: start;
        padding: 0;
    }

    .addIcon {
        display: inline-block;
        margin-right: 8px;
    }

    .addText {
        display: none;
    }
}
