.container {

}

.preorderLabel {
    color: white;
    background-color: var(--color-primary);
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 999px;
    opacity: 0.3;
}

@media (max-width: 640px) {
    h1 {
        font-size: 24px;
    }

    .container {
        flex-direction: column;
        align-items: start;
    }
    .preorderLabel {
        margin-bottom: 20px;
    }
}
