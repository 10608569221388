.container {
    position: fixed;
    bottom: 100px;
    right: 24px;
    width: 60px;
    height: 60px;
    border-radius: 999px;
    z-index: var(--zIndex-overlay);
    background-color: #1972f5;
    font-size: 30px;
}

.container:hover {
    cursor: pointer;
}

.icon {
    color: white;
    animation: shaking 2s infinite;
}

@media screen and (max-width: 503px) {
    .container {
        bottom: 80px;
        width: 54px;
        height: 54px;
        right: 14px;
    }
}

@keyframes shaking {
    0% {
        transform: rotate(0deg);
    }
    5% {
        transform: rotate(5deg);
    }
    10% {
        transform: rotate(0deg);
    }
    15% {
        transform: rotate(-5deg);
    }
    20% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(0deg);
    }
    30% {
        transform: rotate(-5deg);
    }
    35% {
        transform: rotate(0deg);
    }
}
