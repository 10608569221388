.container {
    display: flex;
    justify-content: space-around;
    gap: 24px;
}

.clickable:hover {
    cursor: pointer;
}

.icon {
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.icon p {
    text-align: center;
}

.icon img {
    width: auto;
    height: 100px;
    align-self: center;
}

@media (max-width: 500px) {
    .container {
        flex-direction: column;
        margin-top: 0;
    }
    .icon {
        margin-top: 30px;
    }
}

.container.defaultTheme {
    color: var(--color-primary);
}

.container.darkTheme {
    color: white;
}
