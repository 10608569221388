.container {
    border: solid 1px var(--color-border);
    scroll-padding-right: 10px;
    right: 0;
    bottom: 10vh;
    background-color: white;
    height: 26vh;
    width: 24vw;
    z-index: var(--zIndex-overlay);
    border-bottom-left-radius: var(--border-radius);
    border-top-left-radius: var(--border-radius);
}

.text {
    width: 50%;
}

.content {
    height: 100%;
}

.image {
    width: 50%;
    height: 100%;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

@media (max-width: 1400px) {
    .container {
        width: 33vw;
    }
}

@media (max-width: 1080px) {
    .container {
        width: 50vw;
    }
}

@media (max-width: 800px) {
    .container {
        height: 26vh;
        width: 100vw;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
}
