@import "../../../../_css/variables/variables.css";

.container {
    position: relative;
}

.input {
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: var(--border-radius);
    min-height: var(--input-height);
    padding: 5px 5px 5px 10px;
    flex: 1;
    font-size: 15px;
    width: 100%;
    transition: all 0.3s;
}

.input_default {
    border: solid var(--color-firstLvl-bg-dark) 1px;
}

.input_white {
    border: solid white 1px;
    color: white;
}

.input_label {
    background-color: transparent !important;
    border: none !important;
    padding-left: 0 !important;
    color: inherit !important;
}

.input_gray {
    background-color: rgba(0, 0, 0, 0.04);
}

.input_error {
    border-color: var(--color-error) !important;
    border-width: 3px;
}

.input:focus {
    border-color: var(--color-primary);
    border-width: 3px;
    outline: var(--color-primary);
}

.inputWithEndIcon {
    padding-right: 30px;
}

.endIcon {
    position: absolute;
    top: 5px;
    right: 8px;
    cursor: pointer;
}

.endIcon:hover {
    opacity: 0.8;
}

.error {
    font-size: 14px;
    color: var(--color-error);
    padding: 3px 0;
    min-height: 25px;
}

.textAreaNoResize {
    resize: none;
}
