.container {
    padding: 20px 120px;
}

.navigation {
    opacity: 1;
}

.image {
    position: relative;
    width: 100%;
    height: 630px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
}

.thumbImage {
    position: relative;
    width: 100%;
    height: 215px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
}

@media (max-width: 1600px) {
    .image {
        height: 580px;
    }
}

@media (max-width: 1350px) {
    .container {
        padding: 20px 80px;
    }

    .image {
        height: 490px;
    }

    .thumbImage {
        height: 300px;
    }
}

@media (max-width: 1000px) {
    .container {
        padding: 20px 50px;
    }

    .image {
        height: 320px;
    }

    .thumbImage {
        height: 200px;
    }
}

@media (max-width: 800px) {
    .container {
        padding: 20px;
    }

    .navigation {
        opacity: 0;
    }

    .image {
        height: 230px;
    }

    .thumbImage {
        height: 100px;
    }
}