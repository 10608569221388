.comment {
    background-color: white;
    border-radius: 3px;
    box-shadow: 0 2px 2px rgba(33, 33, 33, .2);
}

.commentBottom {
    width: 25%;
}

.arrowBottom {
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 15px solid white;
    transform: rotate(-180deg);
}

.photo {
    width: 48px;
    height: 48px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 50%;
}

.fullName {
    font-weight: bold;
}

.company {
    color: var(--color-primary);
}

@media (max-width: 780px) {
    .bottom {
        padding-bottom: 30px;
    }
}
