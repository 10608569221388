.headerLine {
    border-bottom: 1px solid lightgrey;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    font-size: 14px;
}

.regularLine {
    border-bottom: 1px solid #f1f1f1;
}

.lookForGiftCard {

}

.sizeText {
    font-size: 12px;
}

.fontPrice {
    color: var(--color-primary-darker);
}

@media (max-width: 600px) {
    .regularLine {
        flex-direction: column;
    }
}