.mobileContainer {
    display: none;
}

.container {
    height: 100%;
}

.iconCartMobile,
.iconCart,
.iconCart i {
    color: white;
}

.subContainer {
    width: 100%;
}

.iconCartMobile {
    position: relative;
    height: 100%;
    z-index: var(--zIndex-navBar);
}

.iconContainer {
    padding: 2px;
}

.partRightMenu {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.menuIcons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

@media (max-width: 1000px) {
    .container {
        display: none;
    }

    .mobileContainer {
        display: flex;
    }
}

@media (max-width: 500px) {
    .menuTextItem {
        font-size: 14px;
    }
}

