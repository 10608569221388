.card {
    width: 408px !important;
    height: 100%;
    min-height: 300px;
    display: flex;
    align-items: center;
}

.card:hover {
    visibility: visible;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-in;
    z-index: var(--zIndex-product-card);
    -webkit-box-shadow: 0 8px 13px 2px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: 0 8px 13px 2px rgba(0, 0, 0, 0.26);
    box-shadow: 0 8px 13px 2px rgba(0, 0, 0, 0.26);
    cursor: pointer;
}

.link {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.text {
    text-align: center;
    color: var(--color-text);
}

.logo {
    height: 64px;
    width: auto;
    object-fit: contain;
}

@media screen and (max-width: 500px) {
    .card {
        width: 100% !important;
    }
}
