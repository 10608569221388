.quantityInput {
    width: 72px;
    border: 1px solid lightgrey;
}

.headerLine {
    border-bottom: 1px solid lightgrey;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    font-size: 14px;
}

.regularLine {
    border-bottom: 1px solid #f1f1f1;
}

.isDiscountPrice {
    text-decoration: line-through;
    color: grey;
}

.photo {
    display: flex;
    width: 100px;
    justify-content: center;
    align-items: center;
}

.photo img {
    object-fit: contain;
}

.productQuantity {
    width: 80%;
}

.product {
    width: 80%;
}

.quantity {
    width: 20%;
}

.total {
    width: 20%;
}

.removeBtn,
.removeBtn i {
    font-size: 10px;
    cursor: pointer;
}

.removeBtn:hover,
.removeBtn:hover i {
    color: var(--color-error);
    transform: scale(1.1);
}

@media (max-width: 780px) {
    .photo {
        width: 30px;
    }

    .productQuantity {
        width: 70%;
    }

    .product {
        width: 70%;
    }

    .quantity {
        width: 30%;
    }

    .total {
        width: 30%;
    }
}

@media (max-width: 600px) {
    .regularLine {
        flex-direction: column;
    }

    .productQuantity {
        width: 100%;
    }
    .quantity {
        justify-content: flex-end;
    }
    .total {
        width: 100%;
    }
}