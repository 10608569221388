.noComment {
    color: var(--color-text-gray);
}

.noCommentText {
    font-weight: bold;
    color: var(--color-text-gray);
}

.reviewsContainer {
    display: flex;
    flex-direction: column;
    gap: 32px;
}
