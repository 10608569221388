.success {
    color: var(--color-success);
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    max-width: 75%;
}

.image {
    width: 80px;
    height: 80px;
}

.container {
    display: flex;
    flex-direction: column;
}

.successContainer {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 32px;
    background: #e4f2fb;
}

.successContainer div {
    gap: 16px;
}

.continue {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 32px;
}

.continueTitle {
    font-size: 16px;
    font-weight: bold;
    color: var(--color-text-gray);
}

.relatedProducts {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.productName {
    font-size: 16px;
    font-weight: bold;
    color: var(--color-primary);
}

@media (max-width: 640px) {
    .success {
        font-size: 16px;
    }

    .image {
        width: 64px;
        height: 64px;
    }

    .container {
        gap: 24px;
    }

    .successContainer {
        gap: 24px;
    }

    .continue {
        gap: 8px;
    }

    .continueTitle {
        font-size: 14px;
    }

    .relatedProducts {
        gap: 8px;
    }

    .productName {
        font-size: 14px;
    }
}
