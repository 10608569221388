.starDefault {
    color: lightgray;
    display: inline-block;
    width: 7px;
    overflow: hidden;
}

.starRightToLeft {
    direction: rtl;
}

.starLeftToRight {
    direction: ltr;
}

.starPicked {
    color: var(--color-secondary) !important;
}

.starPicking {
    color: #68cc9a;
}

.container {
    font-size: 14px !important;
    display: flex;
    align-items: center;
}

.stars {
    display: flex;
    flex-wrap: nowrap !important;
}

.starsEmpty {
    height: 22px;
}

.numberReviews {
    color: var(--color-text-gray);
    font-size: 12px;
}
