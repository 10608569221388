.container {
    color: #201a33 !important;
    font-size: 16px;
    cursor: pointer;
    padding: 3px 2px 3px 0;
    transition: all 0.3s;
    flex-shrink: 0;
    opacity: 0.7;
}

.container_highlighted {
    color: var(--color-primary-darker) !important;
}

.item {
    max-width: 100%;
}

.icon {
    color: var(--color-tertiary);
    margin-left: 2px;
}

@media (hover) {
    .container:hover {
        opacity: 1;
        transform: scale(1.05);
    }
}
