.container {
    position: absolute;
    left: 0;
    right: 0;
    color: #201a33;
}

.bodyWrapper {
    position: absolute;
    top: 0;
}

.body {
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
}

.subGroup {
    padding: 5px 0;
    min-width: 200px;
    font-size: 14px;
}

.title {
    font-size: 18px;
    font-weight: normal;
}


@media (max-width: 1400px) {
    .subGroup {
        font-size: 12px;
        min-width: 140px;
    }
}