.select {
    border: 2px solid var(--color-primary);
    border-radius: var(--border-radius);
}

.select div {
    white-space: break-spaces !important;
}

.stock {
    font-size: 14px;
}

@media (max-width: 640px) {
    .container {
        flex-direction: column;
        align-items: start;
    }

    .stock {
        margin-top: 5px;
    }
}
