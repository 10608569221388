.container_separator {
    border-top: solid 1px rgba(255, 255, 255, 0.5);
}

.menu {
    min-height: 55px;
    padding: 8px 10px;
    cursor: pointer;
}

.menu_active {
    color: #68cc9a !important;
}

.text {
    color: #fff !important;
    font-weight: bold;
}

.subgroup {
    margin-right: 10px;
    border-radius: 4px;
    max-height: 300px;
    overflow-y: auto;
}

.subGroupTitle {
    font-size: 16px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.5) !important;
    padding: 8px 10px;
    cursor: default;
}

.subItem {
    font-size: 13px;
    color: rgba(255, 255, 255, 0.8) !important;
    padding: 8px 10px;
    cursor: pointer;
}
