.container_separator:before {
    content: "";
    width: 2px;
    top: 50%;
    margin-top: -11px;
    position: absolute;
    display: block;
    height: 20px;
    opacity: 0.9;
    background-color: #fff;
}

.arrow {
    position: relative;
}

.arrowInner,
.arrowOuter {
    position: absolute;
    left: 33%;
    top: -13px;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid rgba(0, 0, 0, 0.15);
}

.arrowInner {
    top: -11px;
    border-bottom: 12px solid white;
}

.menu {
    color: var(--color-primary) !important;
    cursor: pointer;
    height: 60px;
    padding: 10px 20px;
    font-size: 15px;
}

.menu.isFirst {
    padding-left: 0;
}

.text,
.textHighlighted {
    font-size: 14px;
    font-weight: 600;
    max-width: 200px;
}

.text {
    padding: 5px 0;
    border-bottom: solid 2px transparent;
    transition: all 0.3s;
}

.textHighlighted {
    font-weight: 900;
    padding: 2px;
}

.image {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 5px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-shrink: 0;
}

.menuSubtitle {
    position: absolute;
    bottom: 13px;
    font-size: 15px;
    font-weight: normal;
    color: #68cc9a !important;
    text-decoration: none !important;
}

.menuSubIcon {
    color: #fff;
    margin-right: 10px;
    font-size: 18px;
    display: block;
}

@media (max-width: 1300px) {
    .menu {
        font-size: 14px;
    }
}
