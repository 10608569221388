.dialog {
    border-radius: 25px;
    width: 400px;
}

.headerDialog {
    height: 60px;
    color: var(--color-primary) !important;
    text-align: center;
}
.title {
    font-weight: bold;
}

.section {
    margin-top: 5%;
    padding: 5%;
}

.icon {
    justify-self: flex-start !important;
}

.card {
    height: 100%;
    width: 100%;
    margin-left: 5%;
    color: var(--color-primary) !important;
}

.bottom {
    height: 100px;
    margin-top: 7%;
}

.btn {
    padding: 5% !important;
}

.logo {
    cursor: pointer;
    padding: 10px 20px;
    width: 48%;
    height: 50px;
    border: 2px solid transparent;
}

.selected {
    border: 2px solid var(--color-primary);
    border-radius: 5px;
    cursor: initial;
}

@media (max-width: 750px) {
    .dialog {
        border-radius: 25px;
        font-size: small;
        margin: 0 !important;
    }
}
@media (max-width: 450px) {
    .dialog {
        max-width: 100%;
        border-radius: 25px;
        font-size: small;
    }
}
