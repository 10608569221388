.button {
    position: absolute;
    top: -15px;
}

.tab {
    background-color: white;
    border-bottom: 2px solid lightgrey;
    height: 48px;
}
