.container {
    background-color: #fff;
    width: 100% !important;
    margin: 0 !important;
}

.strong {
    align-self: center;
}

.cell {
    border: 1px solid rgba(0, 0, 0, 0.1);
}

@media (max-width: 640px) {
    .cellContent {
        font-size: 13px;
    }
}
