.container {
    width: 100%;
    cursor: pointer;
}

.selected {
    border: 1px solid var(--color-secondary);
}

.title {
    font-size: 16px;
    color: var(--color-primary);
}

.description {
    font-size: 13px;
}

.imageContainer {
    max-width: 80px;
    min-width: 80px;
}

.image {
    padding: 5px;
    object-fit: cover;
    width: 100%;
}

.selected {
    border: 1px solid var(--color-secondary);
}
