.accordion {
    cursor: pointer;
    padding: 16px;
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    transition: 0.4s;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba(231, 231, 231, 0.5);
}

.accordionIcon {
    width: 16px;
    height: 16px;
    opacity: 0.7;
}

.accordionContent {
    font-size: 14px;
    color: var(--color-text-gray);
    padding-right: 32px;
    padding-left: 32px;
    padding-bottom: 16px;
}

.label {
    display: flex;
    align-items: center;
    gap: 8px;
}
