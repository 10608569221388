.productCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    width: 148px;
    height: 100%;
    gap: 12px;
    padding: 12px;
    border: 1px solid #e5e5e5;
    border-radius: var(--border-radius);
}

.productCard:hover {
    border: 1px solid #a4a4a4;
}

.imageContainer {
    width: auto;
    height: 80px;
}

.imageContainer img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.placeholder {
    width: 100%;
    height: 100%;
    background-color: #e5e5e5;
}

.price {
    font-size: 14px;
    font-weight: 600;
}
