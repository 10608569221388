.ham {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    -webkit-transition: -webkit-transform 400ms;
    transition: -webkit-transform 400ms;
    transition: transform 400ms;
    transition: transform 400ms, -webkit-transform 400ms;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.hamRotate.active {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}
.hamRotate180.active {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
.line {
    fill: none;
    -webkit-transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
    stroke: white;
    stroke-width: 7;
    stroke-linecap: round;
}
.ham1 .top {
    stroke-dasharray: 40 139;
}
.ham1 .bottom {
    stroke-dasharray: 40 180;
}
.ham1.active .top {
    stroke-dashoffset: -98px;
}
.ham1.active .bottom {
    stroke-dashoffset: -138px;
}