.container {
    border-radius: 3px;
    background-color: white;
    color: #1e1a33;
    min-height: 400px;
    transition: 0.4s all;
    cursor: pointer;
    display: flex;
    flex-direction: column;
}

.container:hover {
    box-shadow: 0 0 20px rgba(33, 33, 33, 0.2);
}

.image {
    height: 250px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.title {
    text-align: center;
    font-size: 22px;
    min-height: 100px;
    max-height: 100px;
    position: relative;
    overflow: hidden;
}

.date {
    background-color: white;
    padding: 5px;
    border-radius: 3px;
}

.text {
    font-size: 18px;
    text-align: justify;
    color: #151515;
    min-height: 150px;
    max-height: 150px;
    overflow: hidden;
    flex: 1;
    position: relative;
}

.readMore {
    height: 60px;
    font-size: 18px;
    text-align: center;
    color: var(--color-primary);
    position: relative;
}

.textGradient {
    position: absolute;
    left: 0;
    right: 0;
    top: -30px;
    height: 30px;
    background-image: linear-gradient(to bottom, transparent, white);
}
