.container {
    padding: 64px 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.container h2 {
    align-self: center;
}
