.container {
    width: 500px;
}

@media (max-width: 1000px) {
    .container {
        width: 300px;
    }
}

@media (max-width: 400px) {
    .container {
        width: 100%;
    }
}