.container {
    margin-right: 10%;
    margin-left: 10%;
    min-width: 40%;
}
.icon {
    color: var(--color-primary) !important;
}

.searchInput {
    background-color: white;
    border-color: var(--color-primary-darker);
    position: relative;
}

.searchInput:active,
.searchInput:focus {
    border-color: var(--color-secondary);
}

.searchResult {
    position: absolute;
    max-width: 40%;
    z-index: var(--zIndex-searchBar);
}

.resultContainer {
    max-height: 500px;
    overflow-y: auto;
    z-index: var(--zIndex-searchBar);
    border: 0;
    border-top-left-radius: 0 !important;
}

.resultContainer::-webkit-scrollbar-thumb {
    background-color: var(--color-secondary);
    border-radius: 20px;
}

.products,
.articles {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.seeMore {
    text-align: center;
    color: var(--color-primary);
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: underline;
}
