/*@font-face {
    font-family: "Montserrat";
    src: url("../../assets/fonts/montserrat/Montserrat-Regular.ttf");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    src: url("../../assets/fonts/montserrat/Montserrat-Light.ttf");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    src: url("../../assets/fonts/montserrat/Montserrat-Bold.ttf");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}*/

@font-face {
    font-family: "Permanent Marker";
    src: url(/static/assets/PermanentMarker-Regular.109a10dc.ttf);
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
