.servicesContainer {
    background-color: var(--color-top-header-bg);
    color: var(--color-footer-font);
    padding: 48px 0
}

.container {
    background-color: var(--color-footer-bg);
    color: var(--color-footer-font);
    padding-top: 30px;
    border-top: 1px solid #1e1a33;
}

.brandLogo {
    width: 160px;
}

.menuContainer {
    gap: 48px;
}

.navigation {
    flex: 2;
}

.informations {
    flex: 1;
}

.allRightsReserved {
    font-size: 12px;
    padding-bottom: 20px;
}

.logos {
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 110px;
}

.link {
    color: #fff !important;
}

.link:hover {
    color: var(--color-secondary) !important;
}

@media (max-width: 800px) {
    .logos {
        text-align: center;
        min-width: calc(100vw - 40px);
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;
    }
}
