.line {
    width: 100%;
    height: 1px;
    opacity: 0.2;
    background: var(--color-text-gray);
}
.keyFeature {
    display: flex;
    gap: 8px;
}

.keyFeatureInfos {
    flex: 1;
}

.keyFeatureInfos > h3 {
    font-size: 16px;
}

.keyFeatureInfos > h4 {
    font-size: 16px;
    font-weight: 400;
    color: var(--color-text-gray);
}

.icon {
    width: 48px;
    height: 48px;
}
